<template>
    <div class="cart-detail-item" style="background-color: #FFFFFF;">
        <div style="position:relative;height: 0;width:100%">
            <div v-if="showtip" class="tip">我们将在确认到账后24小时内为您开通权益</div>
        </div>
        <table style="width:100%;">
            <tr v-for="(cItem, key) in data.detail" :key="key">
                <td style="width:70%;padding:12px;">
                    <a-row>
                        <a-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
                            <img :src="cItem.logo_url" style="width:100%; max-width: 80px;" />
                        </a-col>
                        <a-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
                            <a-col :span="24">
                                <div class="ellipsis" style="margin-top:14px;">
                                    <b>{{cItem.music_name}}</b>
                                </div>

                            </a-col>
                            <a-col :span="24">
                                <span  class="ellipsis" style="margin-top:4px;">
                                    {{cItem.suplr_name}}
                                </span>
                                </a-col>
                        </a-col>
                        <a-col :xs="24" :sm="24" :md="14" :lg="14" :xl="14">
                            <a-col :span="24">
                                <a-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">{{$t('music.labelUseMethod')}}:
                                </a-col>
                                <a-col :xs="24" :sm="24" :md="12" :lg="18" :xl="18">{{cItem.use_mode}}</a-col>
                            </a-col>
                            <a-col :span="24">
                                <a-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">{{$t('music.labelUseScene')}}:</a-col>
                                <a-col :xs="24" :sm="24" :md="12" :lg="18" :xl="18">{{cItem.auth_scene | toAuthScene}}
                                </a-col>
                            </a-col>
                            <a-col :span="24">
                                <a-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">{{$t('music.labelAuthArea')}}:</a-col>
                                <a-col :xs="24" :sm="24" :md="12" :lg="18" :xl="18">{{cItem.auth_area | toArea}}</a-col>
                            </a-col>
                            <a-col :span="24">
                                <a-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">{{$t('music.labelAuthTime')}}:</a-col>
                                <a-col :xs="24" :sm="24" :md="12" :lg="18" :xl="18">{{cItem.auth_time | toAuthPeriod}}
                                </a-col>
                            </a-col>
                        </a-col>
                    </a-row>
                </td>
                <td v-if="key == 0" :rowspan="data.detail.length" style="width:15%;text-align:center;">
                    <a-row>
                        <a-col :span="24">
                            <span v-if="data.status==0">订单待支付</span>
                            <span v-if="data.status==1">订单已取消</span>
                            <span v-if="data.status==2">订单已取消</span>
                            <span v-if="data.status==3">订单支付中</span>
                            <span v-if="data.status==4">订单已支付</span>
                        </a-col>
                        <a-col :span="24">
                            <span>总额：</span>
                            <span style="color:red;">¥{{data.order_amount}}.00</span>
                        </a-col>
                    </a-row>
                    <!-- <span>¥{{data.order_amount}}</span> -->
                </td>
                <td v-if="key == 0" :rowspan="data.detail.length" style="width:15%;text-align:center;">
                    <a-col :span="24">
                        <div v-if="data.status==0" class="order1" @click="onPay(data)">立即支付</div>
                    </a-col>
                    
                    
                    <a-col :span="24">
                        
                        <div v-if="data.status==3" class="order2" @mouseenter="mouseEnter(1)" @mouseout="mouseEnter(0)" >待处理</div>
                    </a-col>
                    <a-col :span="24">
                        <span v-if="data.status==0||data.status==3"   @click="onCancelOrder(data)">取消订单</span>
                    </a-col>
                    <a-col :span="24">
                        <div v-if="data.status==4 && data.is_invoiced==0" class="order3" @click="goApplyReceipt(data)">申请发票</div>
                    </a-col>
                    <a-col :span="24">
                        <span v-if="data.status==4 && data.is_invoiced==1" style="color:#0079F9;cursor: pointer;font-weight: 400;"  @click="$emit('lookReceipt', data)">查看发票</span>
                    </a-col>
                    <a-col :span="24">
                        <span v-if="data.status==2||data.status==1" style="color:#999999;">已关闭</span>
                    </a-col>

                    <!-- <a-col :span="24">
                        <span v-if="data.status==0">待支付</span>
                        <span v-if="data.status==1">已取消</span>
                        <span v-if="data.status==2">已取消</span>
                        <span v-if="data.status==3">支付中</span>
                        <span v-if="data.status==4">支付成功</span>
                    </a-col>
                    <a-col :span="24" style="margin-top:10px;">
                        <router-link :to="`/order/${data.order_id}`" target="_blank"><span
                                class="label-item-span">查看详情</span></router-link>
                    </a-col> -->
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
    import { AuthAreaOptions, BusinessSceneOptions, AuthTimeOptions } from '@/config/options';
    import { toOptionName } from '@/utils/function';
    import MusicPlayIcon from '@/pages/components/music/MusicPlayIcon.vue'
    import OrderHeaderMixins from '@/pages/member/order/components/_mixin';
    export default {
        data() {
            return {
                showtip:false,
            }
        },
        mixins: [OrderHeaderMixins],
        props: {
            data: {
                type: Object,
                default() {
                    return {}
                }
            },
            canPlay: {
                type: Boolean,
                default: false
            }
        },
        filters: {
            toArea(value) {
                return toOptionName(AuthAreaOptions, value);
            },

            toAuthScene(value) {
                return toOptionName(BusinessSceneOptions, value);
            },

            toAuthPeriod(value) {
                return toOptionName(AuthTimeOptions, value);
            }
        },
        components: {
            MusicPlayIcon,
        },
        methods: {
            mouseEnter(f){
                this.showtip=f;
            },
            onPay(scope){
                this.$router.push({path:'/paymusic/'+scope.order_id});
            },
            onCancelOrder(scope){
                let that = this;

                this.$confirm({
                    width: '480px',
                    title: this.$t('template.confirmCancel', {
                        name: this.$t('order.name')
                    }),
                    onOk() {
                        return new Promise((resolve, reject) => {
                            that.confirmVipFunc(scope, resolve, reject);
                        });
                    }
                });
            },
            confirmVipFunc(scope, next, reject) {
                let params = {
                    order_id: scope.order_id || ''
                }

                this.$axios.CancelOrder(params).then(res => {
                    const data = res.data;

                    if (data && data.code == 0) {
                        this.$message.success(this.$t('template.canceledTip', {
                            name: this.$t('order.name')
                        }));
                        next();
                        // this.reloadloadData = true;
                        this.$emit('loadlist')
                    } else {
                        reject()
                    }
                })
            },
        }
    }
</script>

<style lang="scss" scoped>
    .tip{
        position: absolute;
        font-size:12px;
        width:260px;
        height:39px;
        line-height: 39px;
        text-align: center; 
        top:-25px; 
        right:-35px;
        background: #000000;
        opacity: 0.55;
        color:#FFFFFF;
        border-radius: 4px;
    }
    .order1{
        width: 80px;
        height: 32px;
        background: linear-gradient(180deg, #2B9EFF 0%, #3F96E1 100%);
        border-radius: 4px; 
        color:#FFFFFF; 
        line-height: 32px;
        margin:0 auto;
    }
    .order2{
        width: 80px;
        height: 32px;
        background: #AEAEAE; 
        border-radius: 4px; 
        color:#FFFFFF; 
        line-height: 32px;
        margin:0 auto;
    }
    .order3{
        width: 80px;
        height: 32px;
        background: linear-gradient(180deg, #2B9EFF 0%, #3F96E1 100%);
        border-radius: 4px; 
        color:#FFFFFF; 
        line-height: 32px;
        margin:0 auto;
        cursor: pointer;font-weight: 400;
    }
</style>