<template>
    <div>
        <ng-list class="m-top-base" id-key="order_id" :load.sync="loadData" :reload.sync="reloadData"
            :url-func="requestApi" :params="params" :changeData="changeItem" :empty-text="$t('userOrder.tableEmtpy')">
            <template v-slot="{item}">
                <div class="item-header">
                    <order-list-item class="visiable-md hidden-sm" :item="item" @success="onSuccess"
                        @loadlist="onLoadList"></order-list-item>
                </div>
                <music-detail-item :data="item" @success="onSuccess" @loadlist="onLoadList" v-if="orderType==1"  @applyReceipt="onGoApplyReceipt"
                @lookReceipt="onLookReceipt">
                </music-detail-item>
                <vip-detail-item :item="item" @success="onSuccess" @applyReceipt="onGoApplyReceipt"
                    @lookReceipt="onLookReceipt" @loadlist="onLoadList" v-if="orderType==2">
                </vip-detail-item>
            </template>
        </ng-list>

        <dialog-apply-receipt :visible.sync="showDialogReceipt" :data="handleDataApplyReceipt" :order_type="orderType"
            @success="onSuccess" @loadlist="onLoadList"></dialog-apply-receipt>
        <dialog-receipt-result :visible.sync="showDialogReceiptResult" :data="handleDataReceiptResult"
            :invoice_id="handleDataReceiptResult.invoice_id" @success="onSuccess"></dialog-receipt-result>

    </div>
</template>

<script>
    import NgList from '@/components/NgList.vue';
    import MusicDetailItem from './detail/MusicDetailItem.vue';
    import VipDetailItem from './detail/VipDetailItem.vue';
    import DialogApplyReceipt from "./dialog/DialogApplyReceipt.vue";
    import OrderListItem from './detail/OrderListItem.vue'
    import DialogReceiptResult from './result/NewDialogReceiptResult.vue'
    import { DidPayOrderOptions } from '@/config/options';

    export default {
        data() {
            return {
                DidPayOrderOptions,
                radioActiveValue: this.$val.DidPayOrderType.undoAuth,
                // params: null,
                loadData: false,
                reloadData: false,

                showDialogReceipt: false,
                showDialogReceiptResult: false,
                handleDataApplyReceipt: {},
                handleDataReceiptResult: {},
                changeItem: [],
            }
        },

        props: {
            type: String,
            params: Object,
            requestApi: Function,
            orderType: Number,
        },

        components: {
            NgList,
            MusicDetailItem,
            VipDetailItem,
            DialogApplyReceipt,
            OrderListItem,
            DialogReceiptResult,
        },
        methods: {
            onSuccess(scope) {
                this.changeItem = [scope.data];
                this.$nextTick(() => {
                    this.changeItem = [];
                })
                this.closeAllDialog();
            },

            onLoadList() {
                this.reloadData = true;
                this.closeAllDialog();
            },

            closeAllDialog() {
                this.showDialogReceipt = false;
                this.showDialogReceiptResult = false;
                this.handleData = {};
            },

            onGoApplyReceipt(scope) {
                console.log("----------")
                this.handleDataApplyReceipt = Object.assign({}, scope);
                this.showDialogReceipt = true;
            },

            onLookDetail(scope) {
                this.handleDataOrderResult = Object.assign({}, scope);
                this.showDialogOrderResult = true;
            },

            onLookReceipt(scope) {
                this.handleDataReceiptResult = Object.assign({}, scope);
                this.showDialogReceiptResult = true;
            },

            onLookContract(scope) {
                this.handleDataContractResult = Object.assign({}, scope);
                this.showDialogContractResult = true;
            },

        }
    }
</script>

<style lang="scss" scoped>
    ::v-deep {
        .item-right {
            .right {
                width: 400px;
            }
        }

        .item-header {
            background: #F8FAFB;
            border: 1px solid #EBF0F2;
            border-bottom: 1px solid #EBF0F2;
            padding: 12px !important;
            font-weight: 500;
            margin: 0px !important;
        }

        .ant-list-item {
            padding: 0px;
            margin-bottom: 28px;
            border: 1px solid #EBF0F2;
        }
    }

    .auth-code {
        margin-top: $space-ver-sm;
    }

    .good-item-right {
        display: flex;
        justify-content: space-between;
    }

    .operation {
        button {
            height: auto;
        }
    }

    .good-list-item {

        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    @media screen and (max-width: $screen-width-md) {
        .good-item-right {
            width: 100%;
            flex-direction: column;
            margin-top: $space-ver-sm;

            .price {
                margin-bottom: $space-ver-sm;
            }
        }
    }
</style>