<template>
    <div class="cart-detail-item" style="background-color: white;" >
        <div style="position:relative;height: 0;width:100%;">
            <div v-if="showtip && (item.vip_info[0].auth_type ==1 ||item.vip_info[0].auth_type ==2) " class="tip">我们将在确认到账后24小时内为您开通权益</div>
            <div v-if="showtip && item.vip_info[0].auth_type ==3" class="tip2">我们将在确认到账后24小时内为您开通权益</div>
        </div>
        <table style="width:100%; ">
                
            <tr v-for="(scope, key) in item.vip_info" :key="key" style="text-align:center;">
                
                    
                <td style="width:15%;text-align:center;">
                    <a-col :span=24>
                        <img src="@/assets/images/web/member/个人vip.png" v-if="scope.auth_type==2" alt="个人VIP">
                        <img src="@/assets/images/web/member/企业VIP.png" v-if="scope.auth_type==1" alt="企业VIP">
                        <img src="@/assets/images/web/member/省钱卡.png" v-if="scope.auth_type==3" alt="省钱卡">
                    </a-col>
                </td>
                <td style="width:15%;text-align:center;">
                    <a-row>
                        <a-col :span=24 style="text-align:center;">
                            <span v-if="scope.vip_type==1">月度</span>
                            <span v-if="scope.vip_type==2">季度</span>
                            <span v-if="scope.vip_type==3">年度</span>
                            <span v-if="scope.vip_type==4">定制</span>
                        </a-col>
                    </a-row>
                </td>
                <td style="width:40%;text-align:left;padding:12px;">
                    <a-row v-if="scope.auth_type<3">
                        <a-col :span="24">
                            <p v-if="scope.vip_type==4">曲库类型：全部曲库</p>   
                            <p v-if="scope.vip_type!=4">曲库类型：会员专区+音效库</p>   
                        </a-col>
                        <a-col :span="24">
                            <p>下载限制：<span v-if="scope.download_num != '-1' ">{{scope.download_num}}首/天</span><span v-else>不限量/天</span>
                            </p>
                        </a-col>
                        <a-col :span="24">
                            <p v-if="scope.auth_type==1">商用说明：企业商用</p>
                            <p v-if="scope.auth_type==2">商用说明：个人（非商用）</p>
                        </a-col>
                    </a-row>
                    <a-row v-if="scope.auth_type==3">
                        <a-col :span="24">
                            <div style="width:80%;"  v-if="scope.vip_type==1">当月立即获得零售曲库8折券1张以及价值300元的满减券包（含50元代金券*6，单笔零售订单满100可用）</div>
                            <div v-if="scope.vip_type==3" style="width: 80%;">每月获得零售曲库6折券1张以及价值300元的满减券包（含50元代金券*6，单笔零售订单满100可用）</div>
                        </a-col>
                    </a-row>
                </td>
                <td v-if="key == 0" :rowspan="item.vip_info.length" style="width:15%;text-align:center;">
                    <a-row>
                        <a-col :span="24">
                            <span v-if="item.status==0">订单待支付</span>
                            <span v-if="item.status==1">订单已取消</span>
                            <span v-if="item.status==2">订单已取消</span>
                            <span v-if="item.status==3">订单支付中</span>
                            <span v-if="item.status==4">订单已支付</span>
                        </a-col>
                        <a-col :span="24">
                            <span>总额：</span>
                            <span style="color:red;">¥{{item.order_amount}}.00</span>
                        </a-col>
                    </a-row>
                    
                </td>
               
                <td v-if="key == 0" :rowspan="item.vip_info.length"
                    style="width:15%;text-align:center;">
                    <a-col :span="24">
                        <div v-if="item.status==0" class="order1" @click="onpay(item.order_id,item.pay_way)">立即支付</div>
                    </a-col>
                    <a-col :span="24">
                        <span v-if="item.status==0"  @click="onCancelVipOrder(item)">取消订单</span>
                    </a-col>
                    
                    <a-col :span="24">
                        
                        <div v-if="item.status==3" class="order2" @mouseenter="mouseEnter(1)" @mouseout="mouseEnter(0)" >待处理</div>
                    </a-col>
                    
                    <a-col :span="24">
                        <div v-if="item.status==4 && item.is_invoiced==0" class="order3"  @click="goApplyReceipt(item)">申请发票</div>
                    </a-col>
                    <a-col :span="24">
                        <span v-if="item.status==4 && item.is_invoiced==1" style="color:#0079F9;cursor: pointer;font-weight: 400;"  @click="$emit('lookReceipt', item)">查看发票</span>
                    </a-col>
                    <a-col :span="24">
                        <span v-if="item.status==1||item.status==2" style="color:#999999;">已关闭</span>
                    </a-col>
                    <!-- <a-col :span="24">
                        <span v-if="item.status==0">待支付</span>
                        <span v-if="item.status==1">已取消</span>
                        <span v-if="item.status==2">已取消</span>
                        <span v-if="item.status==3">支付中</span>
                        <span v-if="item.status==4">支付成功</span>
                    </a-col> -->
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
    import OrderHeaderMixins from '@/pages/member/order_v2/components/_mixin';
    export default {
        data() {
            return {
                showtip:false,
            }
        },

        props: {
            item: Object,
        },
        mixins: [OrderHeaderMixins],
        methods: {
            mouseEnter(f){
                this.showtip=f;
            },
            onpay(order_id,pay_way){
                this.$router.push({path:'/pay/'+pay_way,query: {order_id:order_id}});
            }
        },
    }
</script>

<style lang="scss" scoped>
    .tip{
        position: absolute;
        font-size:12px;
        width:260px;
        height:39px;
        line-height: 39px;
        text-align: center; 
        top:-5px; 
        right:-35px;
        background: #000000;
        opacity: 0.55;
        color:#FFFFFF;
        border-radius: 4px;
    }
    .tip2{
        position: absolute;
        font-size:12px;
        width:260px;
        height:39px;
        line-height: 39px;
        text-align: center; 
        top:-25px; 
        right:-35px;
        background: #000000;
        opacity: 0.55;
        color:#FFFFFF;
        border-radius: 4px;
    }
    .order1{
        width: 80px;
        height: 32px;
        background: linear-gradient(180deg, #2B9EFF 0%, #3F96E1 100%);
        border-radius: 4px; 
        color:#FFFFFF; 
        line-height: 32px;
        margin:0 auto;
    }
    .order2{
        width: 80px;
        height: 32px;
        background: #AEAEAE; 
        border-radius: 4px; 
        color:#FFFFFF; 
        line-height: 32px;
        margin:0 auto;
    }
    .order3{
        width: 80px;
        height: 32px;
        background: linear-gradient(180deg, #2B9EFF 0%, #3F96E1 100%);
        border-radius: 4px; 
        color:#FFFFFF; 
        line-height: 32px;
        margin:0 auto;
        cursor: pointer;font-weight: 400;
    }
</style>